import React from 'react';
import LayoutTextPage from '../../layout/layoutTextPage';
import { faq } from '../../lib/faq';
import { mq } from '../../theme';

type FAQPageProps = {
    params: {
        slug: string;
    }
}

class FAQPage extends React.PureComponent<FAQPageProps, {}> {
    private getData = (slug: string) => {
        return faq.find((faqItem) => faqItem.slug === slug);
    }
    
    public render() {
        const slug = this.props.params.slug;
        const faqItem = this.getData(slug);
        if(!faqItem) {
            return (<LayoutTextPage title='Niet gevonden'>
                    <div
                    css={mq({
                        maxWidth:'90vw',
                        margin:'auto',
                        })}
                    >
                        Deze vraag is niet gevonden
                    </div>
            </LayoutTextPage>
        )}

        return (
            <LayoutTextPage title={faqItem.question}>
                <div
                    css={mq({
                    maxWidth:'90vw',
                    margin:'auto',
                })}
                >
                    {faqItem.answer}
                </div>   
        </LayoutTextPage>
        )
           
    }
}

export default FAQPage;
